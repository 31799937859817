import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "settings-container clear-background-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Settings_Form_Input = _resolveComponent("Settings_Form_Input")!
  const _component_Settings_Question_Input = _resolveComponent("Settings_Question_Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Settings_Form_Input, {
      onUpdate_forum_settings: _ctx.pull_forum_info,
      onClose_settings: _ctx.close_settings
    }, null, 8, ["onUpdate_forum_settings", "onClose_settings"]),
    _createVNode(_component_Settings_Question_Input, { form_fields: _ctx.form_fields }, null, 8, ["form_fields"])
  ]))
}