import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "signup-container clear-background-container"
}
const _hoisted_2 = { class: "greeting-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, "Good " + _toDisplayString(_ctx.time_of_day), 1),
        _withDirectives(_createElementVNode("input", {
          placeholder: "What is your first name?",
          class: "name-input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.name]
        ])
      ]))
    : _createCommentVNode("", true)
}