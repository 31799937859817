import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: "entry-button-container basic-font orange-text search-field",
    placeholder: "Search",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search_term) = $event))
  }, null, 512)), [
    [_vModelText, _ctx.search_term]
  ])
}