

import { defineComponent } from 'vue';
export default defineComponent({
    props:["need"],
    data(){
        return {
            yes: {},
            partially: {},
            no: {},
            need_class: "None"
        }
    },
    watch:{
        need:{
            handler(){
                this.yes = this.need[1]["Yes"]
                this.partially = this.need[1]["Partially"]
                this.no = this.need[1]["No"]
                this.need_class = this.need[1]["class"]
            },
            deep:true
        }
    }
})
