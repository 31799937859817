

import { defineComponent } from "vue";
const date = new Date();
const hours = date.getHours()
var active: any = (localStorage.getItem("name") == null)
var time = ((hours >= 4 && hours < 12) ? "morning" : (hours >= 12 && hours < 18) ? "afternoon" : "evening")

export default defineComponent({
    data (){
        return {
            time_of_day: time,
            name: "",
            active: active
        }
    },
    methods: {
        submit: function (){

            localStorage.setItem("name", this.name)
            
            this.$emit("saved")
            this.active = false
        }
    }

})
