import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dark-background-container info-display" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.needs, (need) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["need-display", need[1]['class'] + '-container']),
        onClick: ($event: any) => (_ctx.update_need(need))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["need-number-text", need[1]['class']])
        }, _toDisplayString(need[1]["total"]), 3),
        _createElementVNode("div", {
          class: _normalizeClass(["need-text", need[1]['class']])
        }, _toDisplayString(need[0]), 3)
      ], 10, _hoisted_2))
    }), 256))
  ]))
}