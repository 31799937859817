import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "basic-font orange-text name-text" }
const _hoisted_2 = { class: "basic-font date-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "entry-button-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update_entry && _ctx.update_entry(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.entry["Field88"]), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.date_created), 1)
  ]))
}