
import { defineComponent } from 'vue';
import { calculate_needs } from '@/ts/calculate_needs';


export default defineComponent({
    props:["entry"],

    data(){
        return {
            needs: {}
        }
    },
    watch:{
        entry:{
            handler(){
                let unsorted_needs = calculate_needs(localStorage.getItem("field_data"), this.entry)
                let sorted_needs = []
                for (var need in unsorted_needs) {
                    sorted_needs.push([need, unsorted_needs[need]]);
                }
                sorted_needs = sorted_needs.sort(function(a, b) {
                    return a[1]["total"] - b[1]["total"];
                }).reverse();

                this.needs = sorted_needs
            },
            deep: true
        },
        needs() {
            this.$emit("get_need_info", this.needs[0])
        }
    },
    methods:{
        update_need(need: any){
            this.$emit("get_need_info", need)
        }
    },
})

