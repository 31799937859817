
import { defineComponent } from 'vue';

export default defineComponent({
    data(){
        return {
            search_term: ""

        }
    },
    watch:{
        search_term(){
            this.$emit("search", this.search_term)
        }
    }
})

