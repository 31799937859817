import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "clear-background-container main-window" }
const _hoisted_2 = { class: "sidebar-container" }
const _hoisted_3 = { class: "dark-background-container basic-font welcome-message" }
const _hoisted_4 = { class: "dark-background-container entry-select-container" }
const _hoisted_5 = { class: "info-display-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search_Button = _resolveComponent("Search_Button")!
  const _component_Entry_Button = _resolveComponent("Entry_Button")!
  const _component_Settings_Button = _resolveComponent("Settings_Button")!
  const _component_Info_Menu = _resolveComponent("Info_Menu")!
  const _component_Info_Questions_menu = _resolveComponent("Info_Questions_menu")!
  const _component_Info_Notes_Menu = _resolveComponent("Info_Notes_Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, " Welcome, " + _toDisplayString(_ctx.name) + ". ", 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Search_Button, { onSearch: _ctx.update_search }, null, 8, ["onSearch"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (entry) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_Entry_Button, {
              entry: entry,
              onUpdate_select: _ctx.update_select
            }, null, 8, ["entry", "onUpdate_select"])
          ]))
        }), 256))
      ]),
      _createVNode(_component_Settings_Button, { onOpen_settings: _ctx.open_settings }, null, 8, ["onOpen_settings"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Info_Menu, {
        entry: _ctx.selected_entry,
        onGet_need_info: _ctx.get_need_info
      }, null, 8, ["entry", "onGet_need_info"]),
      _createVNode(_component_Info_Questions_menu, { need: _ctx.selected_need }, null, 8, ["need"])
    ]),
    _createVNode(_component_Info_Notes_Menu, { entry_name: _ctx.entry_name }, null, 8, ["entry_name"])
  ]))
}