
import { defineComponent } from 'vue';


export default defineComponent({
    methods:{
        on_click(){
            this.$emit("open_settings")
        }
    }
})
